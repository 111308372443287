import Text, { TextColor, TextSize, TextWeight } from "../Text";
import classNames from "classnames";
import styles from "./Button.module.scss";

export const ButtonColor = Object.freeze({
  Red: styles.red,
  Orange: styles.orange,
});

const Button = ({
  children,
  color = ButtonColor.Orange,
  onClick,
  isFormSubmitButton,
  disabled,
}) => {
  return (
    <button
      className={classNames(styles.button, color, disabled && styles.disabled)}
      onClick={onClick}
      disabled={disabled}
    >
      <Text
        weight={TextWeight.Black}
        color={TextColor.White}
        size={TextSize.Large}
        className={styles.text}
        type={isFormSubmitButton ? "submit" : undefined}
      >
        {children}
      </Text>
    </button>
  );
};

export default Button;
