import { useEffect, useState } from "react";
import styles from "./Background.module.scss";
import classNames from "classnames";

const Background = () => {
  const [isPortrait, setIsPortrait] = useState(false);

  useEffect(() => {
    // set the initial orientation
    setIsPortrait(window.innerWidth < window.innerHeight);

    // set up a handler to respond to orientation changes
    const switchOrientation = (e) => setIsPortrait(e.matches);
    let portrait = window.matchMedia("(orientation: portrait)");
    portrait.addEventListener("change", switchOrientation);

    // clean up the handler before re-running this useEffect
    return () => portrait.removeEventListener("change", switchOrientation);
  }, []);

  const PortraitBackground = (
    <div className={styles.portraitBackgroundImages}>
      <div className={styles.portraitBackgroundContainer}>
        <div className={styles.portraitCol}>
          <img
            src="/Images/GroupL.png"
            className={styles.portraitLeftGroup}
            alt=""
          />
        </div>
        <div className={styles.portraitCol}>
          <img
            src="/Images/GroupR.png"
            className={styles.portraitRightGroup}
            alt=""
          />
        </div>
      </div>
    </div>
  );

  const LandscapeBackground = (
    <div className={styles.landscapeBackgroundImages}>
      <div className={classNames(styles.backgroundRow)}>
        <div className={styles.imageContainer}>
          <img src="/Images/bat.png" className={styles.bat} alt="" />
        </div>
        <div className={styles.imageContainer}>
          <img src="/Images/ape.png" className={styles.ape} alt="" />
        </div>
      </div>

      <div className={classNames(styles.backgroundRow)}>
        <div className={styles.imageContainer}>
          <img src="/Images/max.png" className={styles.max} alt="" />
        </div>
        <div className={styles.imageContainer}>
          <img src="/Images/cat.png" className={styles.cat} alt="" />
        </div>
      </div>

      <div className={classNames(styles.backgroundRow)}>
        <div className={styles.imageContainer}>
          <img src="/Images/felix.png" className={styles.felix} alt="" />
        </div>
        <div className={styles.imageContainer}>
          <img src="/Images/alien.png" className={styles.alien} alt="" />
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.background}>
      <div className={styles.translucent} />
      {isPortrait ? PortraitBackground : LandscapeBackground}
    </div>
  );
};

export default Background;
