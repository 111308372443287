import classNames from "classnames";
import styles from "./TextInput.module.scss";

const TextInput = ({ isError, className, inputRef, ...inputProps }) => {
  return (
    <input
      className={classNames(
        styles.textInput,
        isError && styles.error,
        className,
      )}
      type="text"
      ref={inputRef}
      {...inputProps}
    />
  );
};

export default TextInput;
