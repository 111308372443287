import styles from "./Header.module.scss";
const Header = () => {
  return (
    <header className={styles.container}>
      <img
        src="/Images/Earth8LogoBG.png"
        loading="lazy"
        id="earth8Logo"
        className={styles.logo}
        alt="Earth8 Logo"
      />

      <div className={styles.rightSideContainer}>
        <img
          src="/Images/niceGangLogo.png"
          loading="lazy"
          className={styles.logo}
          alt="NiceGang Logo"
        />
        <img
          src="/Images/perfectDayLogo.png"
          loading="lazy"
          className={styles.logo}
          alt="PerfectDay Logo"
        />
      </div>
    </header>
  );
};

export default Header;
