// This should all be 1:1 with CoinFormCoinImageType on the client

const CoinTypes = Object.freeze({
  Invalid: "Invalid",
  Colorized: "Colorized",
  Silver: "Silver",
  Gold: "Gold",
});

const CoinTypeByUrlParam = Object.freeze({
  "-1": CoinTypes.Invalid,
  0: CoinTypes.Colorized,
  1: CoinTypes.Silver,
  2: CoinTypes.Gold,
});

// Utils

export const getCoinType = (coinTypeUrlParameter) => {
  if (coinTypeUrlParameter) {
    return CoinTypeByUrlParam[coinTypeUrlParameter] ?? CoinTypes.Invalid;
  } else {
    return CoinTypes.Invalid;
  }
};
