import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "../../Components/Header";
import Background from "../../Components/Background";
import ReceiptPage from "../ReceiptPage";
import ErrorPage from "../ErrorPage";
import DeclinePage from "../DeclinePage";
import LocaleContext from "../../Util/LocaleContext";
import { useCallback, useState } from "react";
import FormPage from "../FormPage";
import styles from "./App.module.scss";
import getLocalizedString from "../../Util/GetLocalizedString";

function App() {
  const [formData, setFormData] = useState(null);
  const [locale] = useState(new URLSearchParams(location.search).get("locale"));
  const getLocalizedStringWithLocale = useCallback(
    (key) => getLocalizedString(key, locale),
    [locale],
  );

  return (
    <Router>
      <LocaleContext.Provider value={getLocalizedStringWithLocale}>
        <Header />
        <Background />

        <div className={styles.content}>
          <Switch>
            <Route exact path="/">
              <FormPage saveFormData={setFormData} locale={locale} />
            </Route>
            <Route exact path="/receipt">
              <ReceiptPage formData={formData} />
            </Route>
            <Route exact path="/error">
              <ErrorPage />
            </Route>
            <Route exact path="/declined">
              <DeclinePage />
            </Route>
            <Route path="*">
              <ErrorPage />
            </Route>
          </Switch>
        </div>
      </LocaleContext.Provider>
    </Router>
  );
}

export default App;
