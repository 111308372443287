export function submitCoinCollectionRequest(
  formData,
  verifiedAddress,
  playerID,
  tournamentType,
  onSuccess,
  onError,
) {
  const userData = {
    player_id: playerID,
    tournament_type: tournamentType,
    coin_type: formData.coinType,
    firstname: formData.firstname,
    lastname: formData.lastname,
    email: formData.email,
    optIn: formData.emailOptIn,
    phone: formData.phone,

    address: verifiedAddress.addressLines?.[0] ?? "",
    address_2: verifiedAddress.addressLines?.[1] ?? "",
    state: verifiedAddress.administrativeArea,
    city: verifiedAddress.locality,
    zip: verifiedAddress.postalCode,
    country: verifiedAddress.regionCode,

    sku: formData.sku,
    order_number: formData.orderNumber,
    app_store: formData.appStore,
  };

  fetch(process.env.REACT_APP_COIN_FORM_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  })
    .then((response) => {
      response.ok ? onSuccess() : onError();
    })
    .catch(onError);
}

export function submitDecline(
  playerID,
  tournamentType,
  coinType,
  onSuccess,
  onError,
) {
  const userData = {
    player_id: playerID,
    tournament_type: tournamentType,
    coin_type: coinType,
    deny: true,
  };

  fetch(process.env.REACT_APP_COIN_FORM_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  })
    .then((response) => {
      response.ok ? onSuccess() : onError();
    })
    .catch(onError);
}
