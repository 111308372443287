import styles from "./ChooseAddressButton.module.scss";
import classNames from "classnames";
import Text, { TextSize } from "../Text";

const ChooseAddressButton = ({ address, onClick, label, isSelected }) => {
  return (
    <button
      className={classNames(styles.container, isSelected && styles.selected)}
      onClick={onClick}
    >
      <div className={styles.label}>
        <Text size={TextSize.Small} className={styles.labelText}>
          {label}
        </Text>
      </div>
      <Text size={TextSize.Small} className={styles.address}>
        {address}
      </Text>
    </button>
  );
};

export default ChooseAddressButton;
