import { useHistory } from "react-router-dom";
import { useState, useRef, useContext } from "react";
import DeclineModal from "../../Components/DeclineModal";
import ChooseAddressModal from "../../Components/ChooseAddressModal";
import LoadingSpinner from "../../Components/LoadingSpinner";
import { submitCoinCollectionRequest } from "../../Util/FormSubmissionRequests";
import { makeAddressVerificationRequest } from "../../Util/AddressVerificationRequests";
import styles from "./FormPage.module.scss";
import Text, { TextColor, TextSize, TextWeight } from "../../Components/Text";
import TextInput from "../../Components/TextInput";
import Button, { ButtonColor } from "../../Components/Button";
import classNames from "classnames";
import LocaleContext from "../../Util/LocaleContext";
import CountryDropdown from "../../Components/CountryDropdown";
import { getCoinType } from "../../Util/CoinType";

const FormPage = ({ saveFormData, locale }) => {
  const history = useHistory();
  const [isChooseAddress, setIsChooseAddress] = useState(false);
  const [isDeclinePopup, setIsDeclinePopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorFields, setErrorFields] = useState([]);
  const [verificationAddress, setVerificationAddress] = useState(null);
  const [country, setCountry] = useState(null);

  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const addressLineOneRef = useRef();
  const addressLineTwoRef = useRef();
  const cityLocalityRef = useRef();
  const stateProvinceTerritoryRef = useRef();
  const postalCodeRef = useRef();

  const emailRef = useRef();
  const phoneRef = useRef();
  const emailOptInRef = useRef();

  const urlParams = new URLSearchParams(location.search);
  const playerID = urlParams.get("playerID");

  const tournamentType = urlParams.get("tournamentType");
  const coinType = getCoinType(urlParams.get("coinType"));

  const sku = urlParams.get("sku");
  const orderNumber = urlParams.get("orderNumber");
  const appStore = urlParams.get("appStore");

  const hasPlayerID = !!playerID;
  const hasTournamentData = !!tournamentType;
  const hasPurchaseData = sku && orderNumber && appStore;

  if (!(hasPlayerID && (hasTournamentData || hasPurchaseData))) {
    history.push("/error");
  }

  const getFormData = () => ({
    firstname: firstNameRef.current.value,
    lastname: lastNameRef.current.value,
    phone: phoneRef.current.value,
    email: emailRef.current.value,
    emailOptIn: emailOptInRef.current.checked,
    address: {
      addressLines: [
        addressLineOneRef.current.value,
        addressLineTwoRef.current.value,
      ].filter((line) => line.trim() !== ""),
      locality: cityLocalityRef.current.value,
      administrativeArea: stateProvinceTerritoryRef.current.value,
      postalCode: postalCodeRef.current.value,
      regionCode: country,
    },
    coinType,
    sku,
    orderNumber,
    appStore,
  });

  const submitFormToServer = (verifiedAddress) => {
    const formData = getFormData();

    saveFormData(formData);
    setIsLoading(true);
    submitCoinCollectionRequest(
      formData,
      verifiedAddress,
      playerID,
      tournamentType,
      () => history.push("/receipt"),
      () => history.push("/error"),
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Makes request
    makeAddressVerificationRequest(
      getFormData().address,
      () => {
        setIsLoading(false);
      },
      (errorTypes) => {
        setErrorFields(errorTypes);
      },
      (address) => {
        submitFormToServer(address);
      },
      (address) => {
        setVerificationAddress(address);
        setIsChooseAddress(true);
      },
      () => {
        history.push("/error");
      },
    );

    return false;
  };
  const getLocalizedString = useContext(LocaleContext);

  return (
    <div className={styles.formPage}>
      {isChooseAddress && (
        <ChooseAddressModal
          close={() => setIsChooseAddress(false)}
          submitFormToServer={submitFormToServer}
          userAddress={getFormData().address}
          verificationAddress={verificationAddress}
        />
      )}
      {isDeclinePopup && (
        <DeclineModal
          playerID={playerID}
          tournamentType={tournamentType}
          coinType={coinType}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          closePopup={() => setIsDeclinePopup(false)}
        />
      )}
      {isLoading && <LoadingSpinner />}

      <form onSubmit={onSubmit}>
        <fieldset
          disabled={isLoading || isChooseAddress || isDeclinePopup}
          className={styles.form}
        >
          <div className={styles.descriptionContainer}>
            <span>
              <Text
                size={TextSize.Large}
                color={TextColor.Blue}
                weight={TextWeight.Bold}
              >
                {getLocalizedString(
                  hasPurchaseData
                    ? "form-page_header-line-1_purchase"
                    : "form-page_header-line-1",
                )}
              </Text>
              <br />
              <Text size={TextSize.Large} weight={TextWeight.Bold}>
                {getLocalizedString(
                  hasPurchaseData
                    ? "form-page_header-line-2_purchase"
                    : "form-page_header-line-2",
                )}
              </Text>
            </span>

            {errorFields.length > 0 ? (
              <Text color={TextColor.Red}>
                {getLocalizedString("form-page_address-warning-subheader")}
              </Text>
            ) : (
              <Text>
                {getLocalizedString(
                  hasPurchaseData
                    ? "form-page_subheader_purchase"
                    : "form-page_subheader",
                )}
              </Text>
            )}
          </div>

          <div className={styles.inputContainer}>
            <div className={styles.inputRow}>
              <TextInput
                placeholder={getLocalizedString(
                  "form-page_first-name-placeholder",
                )}
                autoComplete={"given-name"}
                required
                inputRef={firstNameRef}
                className={styles.inputItem}
              />

              <TextInput
                placeholder={getLocalizedString(
                  "form-page_last-name-placeholder",
                )}
                autoComplete={"family-name"}
                required
                inputRef={lastNameRef}
                className={styles.inputItem}
              />
            </div>

            <div className={styles.inputRow}>
              <TextInput
                placeholder={getLocalizedString(
                  "form-page_address-line-1-placeholder",
                )}
                autoComplete={"address-line1"}
                required
                inputRef={addressLineOneRef}
                isError={
                  errorFields.includes("street_number") ||
                  errorFields.includes("route")
                }
                className={classNames(styles.inputItem, styles.addressInput)}
              />
            </div>

            <div className={styles.inputRow}>
              <TextInput
                placeholder={getLocalizedString(
                  "form-page_address-line-2-placeholder",
                )}
                autoComplete={"address-line2"}
                inputRef={addressLineTwoRef}
                isError={errorFields.includes("subpremise")}
                className={classNames(styles.inputItem, styles.apartmentInput)}
              />
            </div>

            <div className={styles.inputRow}>
              <TextInput
                placeholder={getLocalizedString("form-page_city-placeholder")}
                id="city-locality-input"
                required
                inputRef={cityLocalityRef}
                autoComplete={"address-level2"}
                isError={errorFields.includes("locality")}
                className={styles.inputItem}
              />

              <TextInput
                placeholder={getLocalizedString("form-page_region-placeholder")}
                inputRef={stateProvinceTerritoryRef}
                autoComplete={"address-level1"}
                isError={errorFields.includes("administrative_area_level_1")}
                className={styles.inputItem}
              />
            </div>

            <div className={styles.inputRow}>
              <TextInput
                placeholder={getLocalizedString(
                  "form-page_postal-code-placeholder",
                )}
                autoComplete={"postal-code"}
                inputRef={postalCodeRef}
                isError={errorFields.includes("postal_code")}
                className={styles.inputItem}
              />

              <CountryDropdown
                country={country}
                setCountry={setCountry}
                locale={locale}
              />
            </div>

            <div className={styles.inputRow}>
              <TextInput
                type="email"
                placeholder={getLocalizedString("form-page_email-placeholder")}
                autoComplete="email"
                required
                inputRef={emailRef}
                className={styles.inputItem}
              />

              <TextInput
                type="tel"
                placeholder={getLocalizedString("form-page_phone-placeholder")}
                id="phone"
                autoComplete="tel"
                required
                inputRef={phoneRef}
                className={styles.inputItem}
              />
            </div>
          </div>

          <label className={styles.checkboxRow}>
            <input type="checkbox" ref={emailOptInRef} />
            <Text
              color={TextColor.Black}
              size={TextSize.Small}
              className={styles.checkboxLabel}
            >
              {getLocalizedString("form-page_marketing-checkbox-label")}
            </Text>
          </label>

          <div className={styles.buttonRow}>
            {!hasPurchaseData && (
              <Button
                color={ButtonColor.Red}
                onClick={() => setIsDeclinePopup(true)}
                disabled={isLoading}
              >
                {getLocalizedString("form-page_decline-button")}
              </Button>
            )}
            <Button isFormSubmitButton disabled={isLoading}>
              {getLocalizedString("form-page_accept-button")}
            </Button>
          </div>
        </fieldset>
      </form>
    </div>
  );
};

export default FormPage;
