import { useHistory } from "react-router-dom";
import Modal from "../Modal";
import styles from "./DeclineModal.module.scss";
import Text, { TextSize, TextWeight } from "../Text";
import Button from "../Button";
import { submitDecline } from "../../Util/FormSubmissionRequests";
import { useContext } from "react";
import LocaleContext from "../../Util/LocaleContext";

const DeclineModal = ({
  closePopup,
  playerID,
  tournamentType,
  coinType,
  setIsLoading,
  isLoading,
}) => {
  const history = useHistory();
  const getLocalizedString = useContext(LocaleContext);

  return (
    <Modal onClose={isLoading ? () => {} : closePopup}>
      <div className={styles.declineModalContent}>
        <Text size={TextSize.Large} weight={TextWeight.Bold}>
          {getLocalizedString("decline-modal_header")}
        </Text>
        <Text>{getLocalizedString("decline-modal_subheader")}</Text>

        <div className={styles.buttonContainer}>
          <Button
            disabled={isLoading}
            onClick={() => {
              setIsLoading(true);
              submitDecline(
                playerID,
                tournamentType,
                coinType,
                () => {
                  history.push("/declined");
                },
                () => {
                  history.push("/error");
                },
              );
            }}
          >
            {getLocalizedString("decline-modal_confirm")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeclineModal;
