import countries from "i18n-iso-countries";
import styles from "./CountryDropdown.module.scss";
import classNames from "classnames";
import { useContext } from "react";
import LocaleContext from "../../Util/LocaleContext";

const DefaultLanguage = "en";

export default function CountryDropdown({
  country,
  setCountry,
  locale = null,
}) {
  const getLocalizedString = useContext(LocaleContext);

  const localeLanguage = locale?.substring(0, 2) ?? DefaultLanguage;
  const supportedLanguage = countries
    .getSupportedLanguages()
    .includes(localeLanguage)
    ? localeLanguage
    : DefaultLanguage;

  // avoids pulling every country list into the bundle
  countries.registerLocale(
    require(`i18n-iso-countries/langs/${supportedLanguage}.json`),
  );

  const options = Object.keys(countries.getAlpha2Codes()).map((code) => ({
    value: code,
    label: countries.getName(code, supportedLanguage),
  }));

  // Guess the user's country based on their browser locale
  // this methodology is not precise, but it's going to be close enough in most scenarios we care about
  const probableCountry = navigator.language.split("-")?.[1];

  // bring the option with the probable country to the top
  const sortedOptions = [
    options.find((option) => option.value === probableCountry),
    ...options.filter((option) => option.value !== probableCountry),
  ];

  return (
    <select
      required
      className={classNames(styles.dropdown, country ? null : styles.unfilled)}
      onChange={(e) => {
        setCountry(e.target.value);
      }}
      autoComplete={"country"}
    >
      <option value="">
        {getLocalizedString("form-page_country-placeholder")}
      </option>
      {sortedOptions.map((option) => (
        <option value={option.value} key={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}
