import {useContext, useState} from "react";
import { getFormattedAddress } from "../../Util/AddressUtil";
import Modal from "../Modal";
import Text, { TextSize, TextWeight } from "../Text";
import Button from "../Button";
import ChooseAddressButton from "./ChooseAddressButton";
import styles from "./ChooseAddressModal.module.scss";
import LocaleContext from "../../Util/LocaleContext";

const AddressSource = Object.freeze({
  Original: 0,
  Verified: 1,
});

const ChooseAddressModal = ({
  close,
  submitFormToServer,
  userAddress,
  verificationAddress,
}) => {
  const [selectedAddress, setSelectedAddress] = useState(
    AddressSource.Verified,
  );
  const onSubmit = () => {
    close();
    submitFormToServer(
      selectedAddress === AddressSource.Original
        ? userAddress
        : verificationAddress,
    );
  };
  
  const getLocalizedString = useContext(LocaleContext);
  
  return (
    <Modal onClose={close}>
      <div className={styles.container}>
        <Text size={TextSize.Large} weight={TextWeight.Bold}>
          {getLocalizedString("choose-address-modal_header")}
        </Text>
        <Text> {getLocalizedString("choose-address-modal_subheader")}</Text>
        <div className={styles.addressButtonRow}>
          <ChooseAddressButton
            address={getFormattedAddress(userAddress)}
            label={getLocalizedString("choose-address-modal_raw-address-label")}
            onClick={() => setSelectedAddress(AddressSource.Original)}
            isSelected={selectedAddress === AddressSource.Original}
          />
          <ChooseAddressButton
            address={getFormattedAddress(verificationAddress)}
            label={getLocalizedString(
              "choose-address-modal_recommended-address-label",
            )}
            onClick={() => setSelectedAddress(AddressSource.Verified)}
            isSelected={selectedAddress === AddressSource.Verified}
          />
        </div>
        <div className={styles.actionButtonRow}>
          <Button onClick={onSubmit} disabled={selectedAddress === null}>
            {getLocalizedString("choose-address-modal_confirm")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ChooseAddressModal;
