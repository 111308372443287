import { useContext, useEffect } from "react";
import { getFormattedAddress } from "../../Util/AddressUtil";
import styles from "./ReceiptPage.module.scss";
import Text, { TextSize } from "../../Components/Text";
import { useHistory } from "react-router-dom";
import LocaleContext from "../../Util/LocaleContext";

const ReceiptPage = ({ formData }) => {
  const history = useHistory();

  useEffect(() => {
    if (formData) {
      realisticConfetti();
    } else {
      history.push("/error");
    }
  }, []);

  const getLocalizedString = useContext(LocaleContext);

  return (
    <div className={styles.receiptPage}>
      <Text size={TextSize.Large}>
        {getLocalizedString("receipt-page_header")}
      </Text>
      <img
        src={"/Images/coin.png"}
        id="coin"
        alt="stylized coin"
        className={styles.coin}
      />
      {formData && (
        <Text className={styles.address}>
          {formData.firstname +
            " " +
            formData.lastname +
            "\n" +
            getFormattedAddress(formData.address)}
        </Text>
      )}
    </div>
  );
};

// taken from https://github.com/catdad/canvas-confetti
function realisticConfetti() {
  const count = 200;
  const defaults = {
    origin: { y: 0.7 },
  };

  function fire(particleRatio, opts) {
    window.confetti(
      Object.assign({}, defaults, opts, {
        particleCount: Math.floor(count * particleRatio),
      }),
    );
  }

  fire(0.25, {
    spread: 26,
    startVelocity: 55,
  });
  fire(0.2, {
    spread: 60,
  });
  fire(0.35, {
    spread: 100,
    decay: 0.91,
    scalar: 0.8,
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 25,
    decay: 0.92,
    scalar: 1.2,
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 45,
  });
}

export default ReceiptPage;
