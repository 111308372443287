import classNames from "classnames";
import styles from "./Text.module.scss";

export const TextColor = Object.freeze({
  Black: styles.colorBlack,
  White: styles.colorWhite,
  Orange: styles.colorOrange,
  Blue: styles.colorBlue,
  Red: styles.colorRed,
});

export const TextSize = Object.freeze({
  Small: styles.sizeSmall,
  Medium: styles.sizeMedium,
  Large: styles.sizeLarge,
});

export const TextWeight = Object.freeze({
  Normal: styles.weightNormal,
  Bold: styles.weightBold,
  Black: styles.weightBlack,
});

const Text = ({
  children,
  color = TextColor.Black,
  size = TextSize.Medium,
  weight = TextWeight.Normal,
  className,
}) => {
  return (
    <span className={classNames(styles.text, size, color, weight, className)}>
      {children}
    </span>
  );
};

export default Text;
