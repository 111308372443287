import styles from "./ErrorPage.module.scss";
import Text, { TextSize } from "../../Components/Text";
import { useContext } from "react";
import LocaleContext from "../../Util/LocaleContext";

const ErrorPage = () => {
  const getLocalizedString = useContext(LocaleContext);
  return (
    <div className={styles.errorPage}>
      <img
        src="/Images/errorIcon.png"
        id="errorIcon"
        alt="error"
        className={styles.icon}
      />

      <Text size={TextSize.Large}>
        {getLocalizedString("error-page_header")}
      </Text>

      <Text className={styles.body}>
        {getLocalizedString("error-page_body")}
      </Text>
    </div>
  );
};
export default ErrorPage;
