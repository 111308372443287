import Text, { TextSize } from "../../Components/Text";
import styles from "./DeclinePage.module.scss";
import { useContext } from "react";
import LocaleContext from "../../Util/LocaleContext";

export default function DeclinePage() {
  const getLocalizedString = useContext(LocaleContext);

  return (
    <div className={styles.declinePage}>
      <Text size={TextSize.Large}>
        {getLocalizedString("decline-page_body")}
      </Text>
    </div>
  );
}
