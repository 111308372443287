import LocalizedStrings from "../Generated/translations.json";

const DefaultTranslationLanguage = "enUS";

export default function getLocalizedString(key, locale) {
  const translations = LocalizedStrings[key];
  if (!translations) {
    return "MISSING LOC KEY";
  }

  return locale
    ? translations[locale] ?? translations[DefaultTranslationLanguage]
    : translations[DefaultTranslationLanguage];
}
