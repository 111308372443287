import styles from "./Modal.module.scss";

const Modal = ({ onClose, children }) => {
  return (
    <div className={styles.modalContainer}>
      <div className={styles.modalBackdrop} onClick={onClose} />
      <div className={styles.modal}>
        <div className={styles.xButtonRow}>
          <img
            onClick={onClose}
            className={styles.xButton}
            src={"/Images/modalXButton.svg"}
            alt={"close button"}
          />
        </div>
        <div className={styles.modalContent}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
